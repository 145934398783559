<template>
  <div class="working-hour-config">
    <KTCodePreview v-bind:title="'Danh sách giờ công chuẩn'">
      <!-- button create new -->
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <button
              type="button"
              class="btn btn-secondary font-weight-bolder btn-sm"
              @click="configTransport"
            >
              <i style="font-size: 1rem" class="flaticon2-settings"></i>Cấu hình tỉ lệ phụ cấp
            </button>
          </div>

          <div class="ml-3">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
              @click="upsert"
            >
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              chức vụ
            </button>
          </div>
        </div>
      </template>
      <!-- reason configuration table -->
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0">
                <Autosuggest
                  :model="searchJobTitle"
                  :suggestions="filteredJobTitle"
                  @select="onSelectSearch($event)"
                  @change="onSearchJobTitle"
                  :placeholder="'chức vụ'"
                  :limit="10"
                />
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="6">
            <b-table :items="configs" :fields="fields" bordered>
              <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </b-dropdown-item>
                  <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row cols="4">
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col style="display: grid; justify-content: end"
            ><b-pagination-nav
              class="customPagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchConfigs"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template> </b-pagination-nav
          ></b-col>
          <b-col> </b-col>
          <b-col> </b-col>
        </b-row>
        <b-col></b-col>
        <b-col></b-col>

        <!-- Cấu hình chức vụ - số giờ công -->
        <div class="add-model">
          <b-modal ref="my-modal" hide-footer title="Thông tin cấu hình">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Chức vụ</label>
                  <Autosuggest
                    :suggestions="jobTitles"
                    :model="selectedJobTitle.name"
                    :placeholder="'chức vụ'"
                    :limit="10"
                    @change="onInputJobTitle"
                    @select="onJobTitleSelected($event)"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số giờ công</label>
                  <b-input
                    v-model="selectedJobTitle.standardWorkingHour"
                    placeholder="Nhập số giờ công"
                    type="number"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số ngày công</label>
                  <b-input
                    v-model="selectedJobTitle.standardWorkingDay"
                    placeholder="Nhập số ngày công"
                    type="number"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số tiền phụ cấp xăng xe</label>
                  <b-input
                    v-model="selectedJobTitle.transportationAllowanceAmount"
                    placeholder="Nhập số tiền phụ cấp xăng xe"
                    type="number"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              class="mt-2"
              variant="btn btn-primary"
              block
              @click="onSaveModal"
              >Lưu</b-button
            >

            <b-button
              class="mt-3"
              variant="btn btn-outline-secondary"
              block
              @click="hideModal"
              >Đóng</b-button
            >
          </b-modal>
        </div>
        <!-- Cấu hình phụ cấp xăng xe -->
        <div class="add-model">
          <b-modal
            ref="transport-config-modal"
            hide-footer
            title="Thông tin cấu hình tỉ lệ phụ cấp"
            size="lg"
          >
            <div class="transport-config-header justify-content-end">
              <button
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
                @click="addTransportConfig"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </div>

            <b-table
              :items="transportConfigItems"
              :fields="transportConfigFields"
              bordered
            >
              <template v-slot:cell(workingHourFrom)="data">
                <b-form-input
                  v-model="data.item.workingHourFrom"
                  class="text-right"
                ></b-form-input>
              </template>
              <template v-slot:cell(workingHourTo)="data">
                <b-form-input
                  v-model="data.item.workingHourTo"
                  class="text-right"
                ></b-form-input>
              </template>
              <template v-slot:cell(percentage)="data">
                <b-form-input
                  v-model="data.item.percentage"
                  class="text-right"
                ></b-form-input>
              </template>
            </b-table>

            <div class="d-flex submit-config">
              <b-button
                variant="primary"
                size="sm"
                @click="onSaveTransportConfig"
                >Lưu</b-button
              >

              <b-button
                variant="btn btn-outline-secondary"
                size="sm"
                @click="hideTransportConfigModal"
                >Đóng</b-button
              >
            </div>
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.form-group label {
  font-weight: 500;
}
.submit-config button {
  font-weight: 600;
  width: 70px;
}
.submit-config button:nth-child(2) {
  margin-left: 10px;
}
.transport-config-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
  },
  data() {
    return {
      totalPages: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      items: [],
      numberOfPage: null,
      totalPage: '',
      searchName: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '12%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Chức vụ',
          thStyle: { color: '#181c32', width: '240px' },
        },
        {
          key: 'standardWorkingHour',
          label: 'Số giờ công',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'standardWorkingDay',
          label: 'Số ngày công',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      transportConfigItems: [],
      transportConfigFields: [
        {
          key: 'workingHourFrom',
          label: 'Số giờ công từ',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'workingHourTo',
          label: 'Số giờ công đến',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'percentage',
          label: 'Số phần trăm',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
          tdClass: 'text-right',
        },
      ],
      jobTitles: [],
      jobTitleOptions: [],
      selectedJobTitle: {
        id: null,
        name: '',
        standardWorkingHour: 0,
        standardWorkingDay: 0,
        transportationAllowanceAmount: 0,
      },
      limit: 10,
      onSaving: false,
      configs: [],
      searchJobTitle: '',
      selectedSearchJobTitle: '',
      filteredJobTitle: [],
    };
  },
  created() {
    this.fetchJobTitles();
    this.fetchConfigs();
    this.fetchTransportConfig();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình giờ công chuẩn', route: 'Danh sách giờ công chuẩn' },
    ]);
  },
  methods: {
    convertPrice,
    addTransportConfig() {
      const transportDTO = {
        workingHourFrom: 0,
        workingHourTo: 0,
        percentage: 0,
      };
      this.transportConfigItems.push(transportDTO);
    },
    upsertTransportConfig() {
      if (this.onSaving) return;

      this.onSaving = true;
      ApiService.post(
        'job-title-config/transport-configs',
        this.transportConfigItems,
      )
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.fetchConfigs();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
          this.onSaving = false;
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.onSaving = false;
          }
        });
    },
    onSaveTransportConfig() {
      this.upsertTransportConfig();
      this.hideTransportConfigModal();
    },
    configTransport() {
      this.showTransportConfigModal();
    },
    onFilter() {
      this.fetchConfigs();
    },
    onSearchJobTitle(text = '') {
      const filteredData = this.jobTitleOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredJobTitle = [...filteredData];
    },
    onSelectSearch(option) {
      this.selectedSearchJobTitle = option.item.id;
      this.searchJobTitle = option.item.name;
    },
    removeAccents,
    onInputJobTitle(jobTitle = '') {
      if (jobTitle) {
        this.selectedJobTitle.name = jobTitle;
      }
      const filteredData = this.jobTitleOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(jobTitle.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.jobTitles = [...filteredData];
    },
    onJobTitleSelected(option) {
      this.selectedJobTitle.id = option.item.id;
      this.selectedJobTitle.name = option.item.name;
      this.selectedJobTitle.standardWorkingDay =
        option.item.standardWorkingHour;
      this.selectedJobTitle.standardWorkingHour =
        option.item.standardWorkingDay;
      this.selectedJobTitle.standardWorkingHour =
        option.item.transportationAllowanceAmount;
    },
    upsert() {
      this.selectedJobTitle = {
        id: null,
        name: '',
        standardWorkingHour: 0,
        standardWorkingDay: 0,
        transportationAllowanceAmount: 0,
      };
      this.showModal();
    },
    showTransportConfigModal() {
      this.$refs['transport-config-modal'].show();
    },
    hideTransportConfigModal() {
      this.$refs['transport-config-modal'].hide();
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    upsertConfig() {
      if (this.onSaving) return;

      this.onSaving = true;
      ApiService.post('job-title-config/job-titles', this.selectedJobTitle)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.fetchConfigs();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
          this.onSaving = false;
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.onSaving = false;
          }
        });
    },
    onSaveModal() {
      this.upsertConfig();
      this.hideModal();
    },
    fetchJobTitles: async function () {
      ApiService.query('job-title-config/job-titles').then((response) => {
        this.jobTitles =
          this.jobTitleOptions =
          this.filteredJobTitle =
            [...response.data.data];
      });
    },
    fetchTransportConfig: async function () {
      ApiService.query('job-title-config/transport-configs').then(
        (response) => {
          this.transportConfigItems = [...response.data.data];
        },
      );
    },
    fetchConfigs: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const param = {
        page: this.page,
        pageSize: 10,
        name: this.searchJobTitle,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('job-title-config/configs', paramQuery).then(
        (response) => {
          const dataResponse = response.data.data;
          this.configs = [];
          this.totalPages =
            dataResponse.totalPages > 0 ? dataResponse.totalPages : 1;
          this.totalItems = dataResponse.totalItems;
          this.currentPage = dataResponse.currentPage;
          this.configs = [...dataResponse.result];
        },
      );
    },
    deleteItem: async function (item) {
      ApiService.delete('job-title-config' + '/' + item)
        .then((data) => {
          const { status, message } = data.data;
          if (status === 1) {
            this.fetchConfigs();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cấu hình!',
        text: 'Bạn có chắc muốn xóa cấu hình này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.value) {
            this.deleteItem(item);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          this.makeToastFaile(message);
        });
    },
    editItem: function (item) {
      this.selectedJobTitle = { ...item };
      this.showModal();
    },
  },
};
</script>

<style lang="scss">
.working-hour-config {
.table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table thead tr > th {
    text-align: center;
  }
}
</style>
